<template>
	<div>
		<h2>{{ $t('syndication.historique_syndicat') }}</h2>
	    <div class="box">
	        <CustomTable
	            id_table="historique_syndic_etalon"
	            :items="histo_syndic"
	            :busy.sync="table_busy"
	            primaryKey="season_id"
	            :hide_if_empty="true"
	            :rowSelectable="false"
                :externSlotColumns="extern_slot_columns"
				:rawColumns="rawColumns"
	        >
				<template v-slot:custom-slot-cell(saillie_number)="{ data }">
					#{{ data.saillie_number }}
				</template>
				<template v-slot:custom-slot-cell(saillie_tiers)="{ data }">
					<span v-for="tier in data.saillie_tiers" :key="'tier_'+tier.tiers_id">
						<router-link :to="{ name: 'tiersTableauBord', params: { tiers_id: tier.tiers_id }}"> {{ tier.tiers_rs }}</router-link><br/>
					</span>
				</template>
			</CustomTable>
	    </div>
	</div>
</template>

<script type="text/javascript">
    import Navigation from "@/mixins/Navigation.js"
    import Syndic from "@/mixins/Syndic.js"

	export default {
        name: "StallionSyndication",
        mixins: [Navigation, Syndic],
        props: {
            horse: { type: Object, default: null }
        },
        data() {
            return {
                histo_syndic: null,
                table_busy: true,
				rawColumns: ['saillie_history_formatted'],
				extern_slot_columns: [
                    'saillie_number',
					'saillie_tiers'
				]
			}
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                this.table_busy = true
                this.histo_syndic = await this.loadHistoriqueSyndicByStallion(this.horse.horse_id)
                this.table_busy = false
            },
        },
        watch: {
            horse(_) {
                this.init_component()
            }
        },
        components: {
            CustomTable: () => import("GroomyRoot/components/Table/CustomTable"),
            Spinner: () => import("GroomyRoot/components/Logos/LoadingSpinner_35")
        }
    }
</script>